<template>
	<div class="results">
		<h2 class="step-heading"><span>Step 4:</span> Register &amp; view results</h2>
		<p>Provide us with some basic information and get access to the recommended grates based on your input data.</p>

		<div class="call-to-section">
			<h3>Catchment hydraulics and results</h3>
			<div class="row">
				<div class="col-xs-12 col-sm-7 col-md-2">Area of catchment</div>
				<div class="col-xs-12 col-sm-5 col-md-2 result">{{area}}{{areaUnit === 'm' ? 'm²' : 'ft²'}}</div>
				<div class="col-xs-12 col-sm-7 col-md-2">Crossfall</div>
				<div class="col-xs-12 col-sm-5 col-md-2 result">{{reducedCrossfall}}% {{crossfallStatus ? `(${crossfallStatus})` : ''}}</div>
				<div class="col-xs-12 col-sm-7 col-md-2">Standard</div>
				<div class="col-xs-12 col-sm-5 col-md-2 result">{{drainStandard === 'AUS' ? 'AS 3996' : 'EN 1433'}}</div>

				<div class="col-xs-12 col-sm-7 col-md-2">Length of drain</div>
				<div class="col-xs-12 col-sm-5 col-md-2 result">{{length}}{{lengthUnit === 'm' ? 'm' : 'ft'}}</div>
				<div class="col-xs-12 col-sm-7 col-md-2">Pavement geometry</div>
				<div class="col-xs-12 col-sm-5 col-md-2 result">{{pavement}}</div>
				<div class="col-xs-12 col-sm-7 col-md-2">Load class</div>
				<div class="col-xs-12 col-sm-5 col-md-2 result">{{loadClass}}</div>

				<div class="col-xs-12 col-sm-7 col-md-2">Rainfall Intensity</div>
				<div class="col-xs-12 col-sm-5 col-md-2 result">{{rainfall}}{{rainfallUnit === 'm' ? 'mm/hr' : ' inch/hr'}}</div>
				<div class="col-xs-12 col-sm-7 col-md-2">Flow rate</div>
				<div class="col-xs-12 col-sm-5 col-md-2 result">{{flowConverted()}} {{flowRateUnit === 'm' ? 'L/s/m' : 'GPM/ft'}}</div>
				<div class="col-xs-12 col-sm-7 col-md-2">Channel width</div>
				<div class="col-xs-12 col-sm-5 col-md-2 result">{{grateWidthToText(grateNominalWidth)}}</div>
			</div>
		</div>

		<!-- User has registered -->
		<div class="result-table" v-if="registered">
			<div v-if="loading" class="loading">
				<span>loading results...</span>
				<div></div>
			</div>
			<template v-else>
				<!-- Closed system results -->
				<template v-if="featuredGrate">
					<h2 class="step-heading">Selected grate results</h2>
					<p>The grate (slot) recommended must be used in a channel that has adequate hydraulic capacity.</p>
					<div class="highlight results-row" v-if="featuredGrate" :style="{borderColor: getHighlightColor(featuredGrate.Utilisation)}">
						<results-row :isAUS="isAUS()" :grateData="featuredGrate" :highlight="true" :first-result="true" :crossfall="reducedCrossfall"/>
					</div>
					<div v-if="featuredGrate.Utilisation > 100" class="alternative-grates">
						<template v-if="results === null || results.length > 0">
							<h2 class="step-heading">Alternative grates</h2>
							<p>The grates below meet your hydraulic criteria and are listed in decreasing order of hydraulic capacity.</p>

							<div class="results-row" v-for="(result, index) in results" :key="result.ID">
								<results-row :isAUS="isAUS()" :grateData="result" :first-result="index < 1 && !featuredGrate" :crossfall="reducedCrossfall"/>
							</div>
						</template>
						<!--No alternative grates found, show message -->
						<div v-else class="no-results">
							<p>We could not find a grate that meets your hydraulic requirements, try a grate of a higher load class or a grate of a different width.</p>
							<a onclick="backToAco()" class="btn">Back to ACO</a>
						</div>

					</div>
				</template>

				<!-- Open system results -->
				<template v-else>
					<template v-if="results !== null && results.length > 0">
						<h2 class="step-heading">Grate results</h2>
						<p>The list of grates below meet your hydraulic requirements and are based on the load class and grate width selected. The grates are ranked in order of highest {{ $t('message.utilisation') }} to the least {{ $t('message.utilised') }} and must be used in a channel that has adequate hydraulic capacity.</p>
						<template v-if="!featuredGrate || featuredGrate.Utilisation > 100">
							<div class="results-row" v-for="(result, index) in results" :key="result.ID">
								<results-row :isAUS="isAUS()" :grateData="result" :first-result="index < 1 && !featuredGrate" :crossfall="reducedCrossfall"/>
							</div>
						</template>
					</template>

					<!-- User has registered no matching grates -->
					<div class="no-results" v-else>
						<p>We could not find a grate that meets your hydraulic requirements, try a grate of a higher load class or a grate of a different width.</p>
						<button class="btn" @click="$emit('setStep', 1)">Modify Values</button>
					</div>
				</template>

				<div class="cta-row no-print">
					<!-- <div class="cta">
						<h4>I want to try another width</h4>
						<p>Need more capacity or want a more cost effective solution?</p>
						<img src="/images/channel-width.png" alt="">
						<ul class="cta-list">
							<li><a class="btn btn-red btn-cta" @click="$emit('setStep', 3)">Try another width</a></li>
						</ul>
					</div>
					<div class="cta">
						<h4>I want a record</h4>
						<p>Happy with the result?<br><br></p>
						<img src="/images/happy-350.jpg" alt="">
						<ul class="cta-list">
							<li><a class="btn btn-red btn-cta" @click="emailResults()">Email me my result</a></li>
							<li><a class="btn btn-red btn-cta" @click="printResults()">Print this page</a></li>
						</ul>
					</div> -->
					<div class="cta">
						<h4>What about the channel?</h4>
						<p>Choose a channel system that works for my catchment hydraulics.</p>
						<img src="/images/KlassikDrain2.2.png" alt="">
						<ul class="cta-list">
							<li><a class="btn btn-red btn-cta" target="_blank" :href="urlToPrefillHydrolite()">Launch program</a></li>
						</ul>
					</div>
				</div>

				<div v-if="enquiryComplete" class="thanks-row">
					Thank you for your interest! If you wish to make further {{ $t('message.enquiries') }} <a href="#" @click="reset()">click here</a> or modify your values <a href="#" @click="$emit('setStep', 1)">here</a>.
				</div>
				<div v-else class="enquire-row">
					<button class="btn btn-red btn-enquire" @click="showEnquire = true">{{ $t('message.enquire').toUpperCase() }}</button>
					<div>Select products and {{ $t('message.enquire') }} for more info (including blockage factors, higher crossfall and channel hydraulic reporting).</div>
				</div>
			</template>
		</div>
		<!-- User not registered, show form -->
		<div v-else>
			<p>Please complete the registration form to immediately reveal the grate intake calculation results.</p>
			<div class="registration-wrapper">
				<div class="row center-md">
					<div class="col-xs-12 col-md-4">
						<div class="box">
							<registration @success="registered = true; getResults()"></registration>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="backdrop" @click="showEnquire = false" v-if="showEnquire">
			<div class="modal" @click.stop>
				<div class="header">
					<h3>Grate {{ $t('message.Enquiry') }}</h3>
				</div>
				<div class="body">
					<enquire-form @success="enquiryComplete = true" @close="showEnquire = false" :grates="selectedResults()" :submissionID="submissionID"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import Registration from "./registration.vue";
import EnquireForm from "./enquire-form.vue";
import ResultsRow from "./results-row.vue";
import * as Helper from '../helper.js';

export default {
	created: function() {
		this.registered = Boolean(this.getCookie("gp_user"));
		
		if (this.registered) {
			this.registeredPayload = Helper.getJWTPayload(this.getCookie("gp_user"));

			if (this.registeredPayload.projectCountry) {
				var ANZ = ['Australia', 'New Zealand', 'Brunei Darussalam', 'Cambodia', 'Tonga', 'Tuvalu', 'Vanuatu', 'Vietnam', 'Samoa', 'Singapore', 'Solomon Islands', 'Thailand', 'Papua New Guinea', 'Philippines', 'Micronesia', 'Myanmar (Burma)', 'Nauru', 'Palau', 'Kiribati', 'Malaysia', 'Marshall Islands', 'Indonesia', 'Fiji', 'Tonga'];
				if (ANZ.includes(this.registeredPayload.projectCountry)) {
					console.log("Setting en-AU locale for " + this.registeredPayload.projectCountry);
					this.$root.$i18n.locale = 'en-AU';
				} else {
					console.log("Setting en-US locale for " + this.registeredPayload.projectCountry);
					this.$root.$i18n.locale = 'en-US';
				}
			}
			this.getResults();
		}
	},
	props: {
		pavement: String,
		rainfall: Number,
		rainfallUnit: String,
		area: Number,
		areaUnit: String,
		length: Number,
		lengthUnit: String,
		reducedCrossfall: Number,
		crossfallStatus: String,
		flowRateUnit: String,
		flowRate: Number,
		drainStandard: String,
		loadClass: String,
		grateNominalWidth: Number,
		preselectedGrate: String,
	},
	data: function() {
		return {
			registered: false,
			showEnquire: false,
			results: null,
			submissionID: null,
			featuredGrate: null, //This is the preselected grate
			enquiryComplete: false,
			loading: false
		}
	},
	methods: {
		urlToPrefillHydrolite() {
			return `http://hydrolite.acotechsupport.com/?rainfall=${this.rainfall}&area=${this.area}&units=${this.areaUnit}&length=${this.length}&${this.grateNominalWidth}`
		},
		getCookie: function(name) {
			let value = "; " + document.cookie;
			let parts = value.split("; " + name + "=");
			if (parts.length == 2) return parts.pop().split(";").shift();
		},
		reset: function() {
			//This gets called if a user to make further enquires
			//It's required we resubmit the input values so we get a new submission
			//This is due to the submission/enqury DB relationship
			this.showEnquire = false;
			this.results = null;
			this.submissionID = null;
			this.featuredGrate = null;
			this.enquiryComplete = false;

			this.getResults();
		},
		getResults: function() {
			this.loading = true;
			axios.post(
				"/api/submission",
				{
					Pavement: this.pavement,
					Rainfall: this.rainfall,
					RainfallUnit: this.rainfallUnit,
					Area: this.area,
					AreaUnit: this.areaUnit,
					Length: this.length,
					LengthUnit: this.lengthUnit,
					Crossfall: this.reducedCrossfall,
					FlowRateUnit: this.flowRateUnit,
					FlowRate: this.flowRate,
					DrainStandard: this.drainStandard,
					LoadClass: this.loadClass,
					GrateNominalWidth: this.grateNominalWidth,
					PreselectedGrate: this.preselectedGrate
				}).then((res) => {
					this.loading = false;
					let results = res.data.Grates;
					
					//Set some values in the results and find/remove preselected grate
					if(results !== null){
						for(let i = 0; i < results.length; i++){
							results[i].selected = false;
						}
					}

					//Preselected grate will always appear first, so extract from results array
					if(this.preselectedGrate){
						this.featuredGrate = results[0];
						results.shift();
					}
					
					this.results = results;
					this.submissionID = res.data.SubmissionID;
				}).catch((err) => {
					this.loading = false;
					console.log(err);
				});
		},
		selectedResults: function(){
			//returns results that have been selected
			let selected = [];
			if(this.featuredGrate && this.featuredGrate.selected){
				selected.push(this.featuredGrate);
			}

			for(let result of this.results){
				if(result.selected) {
					selected.push(result);
				}
			}

			return selected;
		},
		flowConverted: function(){
			return this.flowRateUnit === 'm' ? this.flowRate : Helper.asGPMft(this.flowRate);
		},
		grateWidthToText: function(width){
			switch (width) {
				case 0:
					return 'Slot';
				case 50:
					return '50mm (2")';
				case 100:
					return '100mm (4")';
				case 200:
					return '200mm (8")';
				case 300:
					return '300mm (12")';
			}
		},
		getHighlightColor: function(percentage){
			switch (true) {
				case percentage < 75:
					return "green";
				case percentage < 90:
					return "#ff850e";
				case percentage < 100:
					return "#ff3b3b"
				default:
					return "#ff3b3b";
			}
		},
		isAUS: function() {
			return Boolean(this.drainStandard === 'AUS');
		}
	},
	components: {
		'registration': Registration,
		'enquire-form': EnquireForm,
		'results-row': ResultsRow
	}
}
</script>
