<template>
	<div>
		<div v-if="success" style="text-align: center;">
			<p>Thank-you for the {{ $t('message.enquiry') }}, one of our ACO team members will be in touch shortly.</p>
			<button class="btn" @click="$emit('close')">Close</button>
		</div>
		<form v-else class="enquire-form" @submit.prevent="sendEnquiry()">
			<ul>
				<li v-for="(grate, index) in grates" :key="index">{{grate.PartNo}} - {{grate.Name}}</li>
			</ul>

			<input v-validate="'required'" type="text" name="name" v-model="name" placeholder="Last Name, First Name"/>
			<div class="vee-err err-bump-up" v-show="errors.has('name')">{{ errors.first('name') }}</div>

			<input v-validate="'required'" type="tel" name="phone" v-model="phone" placeholder="Your phone"/>
			<div class="vee-err err-bump-up" v-show="errors.has('phone')">{{ errors.first('phone') }}</div>

			<input v-validate="'required'" type="email" name="email" v-model="email" placeholder="Email"/>
			<div class="vee-err err-bump-up" v-show="errors.has('email')">{{ errors.first('email') }}</div>

			<input v-validate="'required'" type="text" name="company" v-model="company" placeholder="Company"/>
			<div class="vee-err err-bump-up" v-show="errors.has('company')">{{ errors.first('company') }}</div>

			<input v-validate="'required'" type="text" name="projectName" v-model="projectName" placeholder="Project Name"/>
			<div class="vee-err err-bump-up" v-show="errors.has('projectName')">{{ errors.first('projectName') }}</div>

			<select v-validate="'required'" type="text" name="projectCountry" v-model="projectCountry" :class="{ placeholder: projectCountry == ''}">
				<option disabled selected value="">Project Country</option>
				<option>Afghanistan</option>
				<option>Albania</option>
				<option>Algeria</option>
				<option>American Samoa</option>
				<option>Andorra</option>
				<option>Angola</option>
				<option>Anguilla</option>
				<option>Antarctica</option>
				<option>Antigua and Barbuda</option>
				<option>Argentina</option>
				<option>Armenia</option>
				<option>Aruba</option>
				<option>Australia</option>
				<option>Austria</option>
				<option>Azerbaijan</option>
				<option>Bahamas</option>
				<option>Bahrain</option>
				<option>Bangladesh</option>
				<option>Barbados</option>
				<option>Belarus</option>
				<option>Belgium</option>
				<option>Belize</option>
				<option>Benin</option>
				<option>Bermuda</option>
				<option>Bhutan</option>
				<option>Bolivia</option>
				<option>Bosnia and Herzegovina</option>
				<option>Botswana</option>
				<option>Brazil</option>
				<option>British Indian Ocean Territory</option>
				<option>British Virgin Islands</option>
				<option>Brunei</option>
				<option>Bulgaria</option>
				<option>Burkina Faso</option>
				<option>Burundi</option>
				<option>Cambodia</option>
				<option>Cameroon</option>
				<option>Canada</option>
				<option>Cape Verde</option>
				<option>Cayman Islands</option>
				<option>Central African Republic</option>
				<option>Chad</option>
				<option>Chile</option>
				<option>China</option>
				<option>Christmas Island</option>
				<option>Cocos Islands</option>
				<option>Colombia</option>
				<option>Comoros</option>
				<option>Cook Islands</option>
				<option>Costa Rica</option>
				<option>Croatia</option>
				<option>Cuba</option>
				<option>Curacao</option>
				<option>Cyprus</option>
				<option>Czech Republic</option>
				<option>Democratic Republic of the Congo</option>
				<option>Denmark</option>
				<option>Djibouti</option>
				<option>Dominica</option>
				<option>Dominican Republic</option>
				<option>East Timor</option>
				<option>Ecuador</option>
				<option>Egypt</option>
				<option>El Salvador</option>
				<option>Equatorial Guinea</option>
				<option>Eritrea</option>
				<option>Estonia</option>
				<option>Ethiopia</option>
				<option>Falkland Islands</option>
				<option>Faroe Islands</option>
				<option>Fiji</option>
				<option>Finland</option>
				<option>France</option>
				<option>French Polynesia</option>
				<option>Gabon</option>
				<option>Gambia</option>
				<option>Georgia</option>
				<option>Germany</option>
				<option>Ghana</option>
				<option>Gibraltar</option>
				<option>Greece</option>
				<option>Greenland</option>
				<option>Grenada</option>
				<option>Guam</option>
				<option>Guatemala</option>
				<option>Guernsey</option>
				<option>Guinea</option>
				<option>Guinea-Bissau</option>
				<option>Guyana</option>
				<option>Haiti</option>
				<option>Honduras</option>
				<option>Hong Kong</option>
				<option>Hungary</option>
				<option>Iceland</option>
				<option>India</option>
				<option>Indonesia</option>
				<option>Iran</option>
				<option>Iraq</option>
				<option>Ireland</option>
				<option>Isle of Man</option>
				<option>Israel</option>
				<option>Italy</option>
				<option>Ivory Coast</option>
				<option>Jamaica</option>
				<option>Japan</option>
				<option>Jersey</option>
				<option>Jordan</option>
				<option>Kazakhstan</option>
				<option>Kenya</option>
				<option>Kiribati</option>
				<option>Kosovo</option>
				<option>Kuwait</option>
				<option>Kyrgyzstan</option>
				<option>Laos</option>
				<option>Latvia</option>
				<option>Lebanon</option>
				<option>Lesotho</option>
				<option>Liberia</option>
				<option>Libya</option>
				<option>Liechtenstein</option>
				<option>Lithuania</option>
				<option>Luxembourg</option>
				<option>Macau</option>
				<option>Macedonia</option>
				<option>Madagascar</option>
				<option>Malawi</option>
				<option>Malaysia</option>
				<option>Maldives</option>
				<option>Mali</option>
				<option>Malta</option>
				<option>Marshall Islands</option>
				<option>Mauritania</option>
				<option>Mauritius</option>
				<option>Mayotte</option>
				<option>Mexico</option>
				<option>Micronesia</option>
				<option>Moldova</option>
				<option>Monaco</option>
				<option>Mongolia</option>
				<option>Montenegro</option>
				<option>Montserrat</option>
				<option>Morocco</option>
				<option>Mozambique</option>
				<option>Myanmar</option>
				<option>Namibia</option>
				<option>Nauru</option>
				<option>Nepal</option>
				<option>Netherlands</option>
				<option>Netherlands Antilles</option>
				<option>New Caledonia</option>
				<option>New Zealand</option>
				<option>Nicaragua</option>
				<option>Niger</option>
				<option>Nigeria</option>
				<option>Niue</option>
				<option>North Korea</option>
				<option>Northern Mariana Islands</option>
				<option>Norway</option>
				<option>Oman</option>
				<option>Pakistan</option>
				<option>Palau</option>
				<option>Palestine</option>
				<option>Panama</option>
				<option>Papua New Guinea</option>
				<option>Paraguay</option>
				<option>Peru</option>
				<option>Philippines</option>
				<option>Pitcairn</option>
				<option>Poland</option>
				<option>Portugal</option>
				<option>Puerto Rico</option>
				<option>Qatar</option>
				<option>Republic of the Congo</option>
				<option>Reunion</option>
				<option>Romania</option>
				<option>Russia</option>
				<option>Rwanda</option>
				<option>Saint Barthelemy</option>
				<option>Saint Helena</option>
				<option>Saint Kitts and Nevis</option>
				<option>Saint Lucia</option>
				<option>Saint Martin</option>
				<option>Saint Pierre and Miquelon</option>
				<option>Saint Vincent and the Grenadines</option>
				<option>Samoa</option>
				<option>San Marino</option>
				<option>Sao Tome and Principe</option>
				<option>Saudi Arabia</option>
				<option>Senegal</option>
				<option>Serbia</option>
				<option>Seychelles</option>
				<option>Sierra Leone</option>
				<option>Singapore</option>
				<option>Sint Maarten</option>
				<option>Slovakia</option>
				<option>Slovenia</option>
				<option>Solomon Islands</option>
				<option>Somalia</option>
				<option>South Africa</option>
				<option>South Korea</option>
				<option>South Sudan</option>
				<option>Spain</option>
				<option>Sri Lanka</option>
				<option>Sudan</option>
				<option>Suriname</option>
				<option>Svalbard and Jan Mayen</option>
				<option>Swaziland</option>
				<option>Sweden</option>
				<option>Switzerland</option>
				<option>Syria</option>
				<option>Taiwan</option>
				<option>Tajikistan</option>
				<option>Tanzania</option>
				<option>Thailand</option>
				<option>Togo</option>
				<option>Tokelau</option>
				<option>Tonga</option>
				<option>Trinidad and Tobago</option>
				<option>Tunisia</option>
				<option>Turkey</option>
				<option>Turkmenistan</option>
				<option>Turks and Caicos Islands</option>
				<option>Tuvalu</option>
				<option>U.S. Virgin Islands</option>
				<option>Uganda</option>
				<option>Ukraine</option>
				<option>United Arab Emirates</option>
				<option>United Kingdom</option>
				<option>United States</option>
				<option>Uruguay</option>
				<option>Uzbekistan</option>
				<option>Vanuatu</option>
				<option>Vatican</option>
				<option>Venezuela</option>
				<option>Vietnam</option>
				<option>Wallis and Futuna</option>
				<option>Western Sahara</option>
				<option>Yemen</option>
				<option>Zambia</option>
				<option>Zimbabwe</option>
			</select>

			<input type="text" name="projectState" v-model="projectState" placeholder="Project State"/>

			<input type="text" v-model="projectZipCode" placeholder="Project Post / Zip Code"/>

			<select v-validate="'required'" v-model="enquiryType" name="enquiryType" :class="{ placeholder: enquiryType == ''}">
				<option disabled selected value="">Type of enquiry</option>
				<option value="Channel Sizing Required" style="color: #454545;">Channel Sizing Required</option>
				<option value="Pricing/Availability">Pricing/Availability</option>
				<option value="Higher Crossfall">Higher Crossfall - please specify</option>
				<option value="Applying Blockage">Apply Blockage Factor - please specify</option>
				<option value="Other">Other</option>
			</select>
			<div class="vee-err err-bump-up" v-show="errors.has('enquiryType')">{{ errors.first('enquiryType') }}</div>

			<textarea v-validate="custom_rules" name="additional_input" v-model="additional" placeholder="Any further details" rows="4"></textarea>
			<div class="vee-err err-bump-up" v-show="errors.has('additional_input')">This field is required due to the type of enquiry</div>
			
			<div class="pull-right">
				<button :class="['btn', {loading: loading}]"><span>Submit</span></button>
			</div>
		</form>
	</div>
</template>

<script>
import axios from 'axios';
import VeeValidate from 'vee-validate';

export default {
	computed: {
		//Use this fancy pants function to make the additional comments required
		//Only when the enquiry type is set to Other
		custom_rules() {
			return this.enquiryType === "Other" ? 'required' : '';
		}
	},
	created: function() {
		if(this.getCookie("gp_user")) {
			axios.get("/api/mydata").then((res) => {
					const result = res.data;
					this.name = result.Name;
					this.phone = result.Phone;
					this.email = result.Email;
					this.company = result.Company;
					this.projectName = result.ProjectName;
					this.projectState = result.ProjectState;
					this.projectCountry = result.ProjectCountry;
					this.projectZipCode = result.ProjectZipCode;
				}).catch((err) => {
					console.log(err);
				});
		}
	},
	props: {
		grates: Array,
		submissionID: Number
	},
	data: function(){
		return {
			loading: false,
			success: false,

			name: '',
			phone: '',
			email: '',
			company: '',
			projectName: '',
			projectState: '',
			projectCountry: '',
			projectZipCode: '',
			enquiryType: '',
			additional: '',
		}
	},
	methods: {
		getCookie: function(name) { //TODO move to helpers.js
			let value = "; " + document.cookie;
			let parts = value.split("; " + name + "=");
			if (parts.length == 2) return parts.pop().split(";").shift();
		},
		sendEnquiry: function() {
			//Validate fields
			this.$validator.validateAll().then((result) => {
				if (result && !this.loading) {
					//Send enquiry request
					this.loading = true;
					axios.post(
						"/api/enquire", 
						{
							Name: this.name,
							Phone: this.phone,
							Email: this.email,
							Company: this.company,
							ProjectName: this.projectName,
							ProjectState: this.projectState,
							ProjectCountry: this.projectCountry,
							ProjectZipCode: this.projectZipCode,
							EnquiryType: this.enquiryType,
							Additional: this.additional,
							GratesSerialized: JSON.stringify(this.grateIDs()), //stored as JSON in the DB
							SubmissionID: this.submissionID
						}).then((res) => {
							this.loading = false;
							this.enquiryType = '';
							this.additional = '';
							this.success = true;

							this.$emit("success"); //Hides enquire button, show thanks message (in resutls component)
						}).catch((err) => {
							this.loading = false;
							console.log(err);
						});
				}
			});
		},
		grateIDs: function() {
			return this.grates.map(g => g.ID);
		}
	}
}
</script>