var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "water-run-off" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("p", [_vm._v("Calculated uniform lateral flow.")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-3 col-md-2" }, [
        _c("div", { staticClass: "box" }, [_vm._v(_vm._s(_vm.flowConverted()))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-6 col-md-4" }, [
        _c(
          "select",
          {
            class: { placeholder: _vm.flowRateUnit == "" },
            domProps: { value: _vm.flowRateUnit },
            on: {
              change: function($event) {
                return _vm.$emit("update:flowRateUnit", $event.target.value)
              }
            }
          },
          [
            _c("option", { attrs: { disabled: "", selected: "", value: "" } }, [
              _vm._v("Select")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "m" } }, [_vm._v("L/s/m")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "i" } }, [_vm._v("GPM/ft")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-3 col-md-2" }, [
        _c("div", { staticClass: "box" }, [
          _vm._v(_vm._s(_vm.reducedCrossfall) + "%")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-6 col-md-4" }, [
        _vm.crossfallStatus
          ? _c("div", { staticClass: "box relative" }, [
              _c("span", { staticClass: "highlight-red" }, [
                _vm._v(_vm._s("Crossfall " + _vm.crossfallStatus + "."))
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "info",
                attrs: { src: "/icons/info.svg" }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "info-text" }, [
                _vm._v(
                  "For a quick result, calculations are limited to 0.5% to 3% crossfall. ACO can offer solutions up to 9% on " +
                    _vm._s(_vm.$t("message.enquiry")) +
                    "."
                )
              ])
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12 col-md-offset-6 col-md-2" }, [
        _c("div", { staticClass: "box" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              staticStyle: { width: "100%" },
              on: {
                click: function($event) {
                  return _vm.$emit("next", 3)
                }
              }
            },
            [_vm._v("Next")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "step-heading" }, [
      _c("span", [_vm._v("Step 2:")]),
      _vm._v(" Surface water run-off")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-3 col-md-2" }, [
      _c("div", { staticClass: "box" }, [_vm._v("Flow rate")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-3 col-md-2" }, [
      _c("div", { staticClass: "box" }, [_vm._v("Crossfall")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }