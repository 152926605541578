<template>
	<div class="relevent-system">
		<h2 class="step-heading"><span>Step 3:</span> Identifying relevant system</h2>
		<p v-if="!preselectedGrate">Refining the selection criteria to identify correct load class and grate width.</p>
		<p v-else>The fields below have been pre-filled based on the preselected grate.</p>

		<div class="row">
			<div class="col-xs-12 col-md-4">
				<div class="box relative">Drain Standard 
					<img class="info" src="/icons/info.svg" />
					<div class="info-text">National regulatory authority that governs grates, if in doubt contact ACO to assist with Load Class Selection</div>
				</div>
			</div>
			<div class="col-xs-12 col-md-6">
			<select
				:value="drainStandard"
				@change="updateDrain"
				:class="{ placeholder: drainStandard == ''}"
				v-validate="'required'"
				name="drainStandard"
				:disabled="preselectedGrate">
				<option disabled selected value="">Select</option>
				<option value="AUS">AS 3996 (Australasia) </option>
				<option value="NA">EN 1433 (North America)</option>
			</select>
			<div class="vee-err" v-show="errors.has('drainStandard')">{{ errors.first('drainStandard') }}</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 col-md-4">
				<div class="box relative">Load Class 
					<img v-show="drainStandard == 'AUS'" class="info" src="/icons/info.svg" />
					<div v-show="drainStandard == 'AUS'" class="info-text">Weights shown for AS 3996 drain standard load classses are approximate individual wheel loads</div>
				</div>
			</div>
			<div class="col-xs-12 col-md-6">
			<select
				:value="loadClass"
				@change="$emit('update:loadClass', $event.target.value)"
				:disabled="drainStandard == '' || preselectedGrate"
				:class="{ placeholder: loadClass == ''}"
				v-validate="'required'"
				name="loadClass">
				<option disabled selected value="">Select</option>
				<template v-if="drainStandard == 'AUS'">
					<option value="A 10">A10kN (330kg)</option>
					<option value="B 80">B80kN (2,670kg)</option>
					<option value="C 150">C150kN (5,000kg)</option>
					<option value="D 210">D210kN (8,000kg)</option>
					<option value="E 400">E400kN (13,700kg)</option>
					<option value="F 600">F600kN (20,000kg)</option>
					<option value="G 900">G900kN (30,000kg)</option>
				</template>
				<template v-else>
					<option value="A 15">Class A - 3,372 lbs - 15kN (44-70psi)</option>
					<option value="B 125">Class B - 28,100 lbs - 125kN (369-580psi)</option>
					<option value="C 250">Class C - 56,200 lbs - 250kN (739-1160psi)</option>
					<option value="D 400">Class D - 89,920 lbs - 400kN (1182-1856psi)</option>
					<option value="E 600">Class E - 134,800 lbs - 600kN (1773-2785psi)</option>
					<option value="F 900">Class F - 202,320 lbs - 900kN (2659-4177psi)</option>
				</template>
			</select>
			<div class="vee-err" v-show="errors.has('loadClass')">{{ errors.first('loadClass') }}</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 col-md-4">
				<div class="box relative">Grate Nominal Width 
					<img class="info" src="/icons/info.svg" />
					<div class="info-text">The assumption is that the channel can handle the flow.</div>
				</div>
			</div>
			<div class="col-xs-12 col-md-6">
			<select
				:value="grateNominalWidth"
				@change="$emit('update:grateNominalWidth', $event.target.value == '' ? null : Number($event.target.value))"
				:class="{ placeholder: grateNominalWidth == null}"
				v-validate="'required'"
				name="grateNominalWidth"
				:disabled="preselectedGrate">
				<option disabled selected value="">Select</option>
				<option value="0">Slot</option>
				<option value="50">50mm (2")</option>
				<option value="100">100mm (4")</option>
				<option value="200">200mm (8")</option>
				<option value="300">300mm (12")</option>
			</select>
			<div class="vee-err" v-show="errors.has('grateNominalWidth')">{{ errors.first('grateNominalWidth') }}</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xs-12 col-md-offset-8 col-md-2">
				<div class="box">
					<button class="btn" style="width: 100%" @click="nextPage()">Next</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		drainStandard: String,
		loadClass: String,
		grateNominalWidth: Number,
		preselectedGrate: String
	},
	methods: {
		updateDrain(e){
			this.$emit('update:drainStandard', e.target.value);
			this.$emit('update:loadClass', '');
			// this.$emit('update:grateNominalWidth', null);
		},
		nextPage: function() {
			this.$validator.validateAll().then((result) => {
				if (result) {
					this.$emit('next', 4);
				}
			});
		}
	}
}
</script>