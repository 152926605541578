import convert from 'convert-units';

export function getURLParameter(name) {
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

export function getCookie(name) {
	let value = "; " + document.cookie;
	let parts = value.split("; " + name + "=");
	if (parts.length == 2) return parts.pop().split(";").shift();
}

export function getJWTPayload(token) {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace('-', '+').replace('_', '/');
	return JSON.parse(window.atob(base64));
}

export function asGPMft(lsm) {
	return roundTo(lsm * 4.8312, 5);
}

export function calculateFlow(rainfall, rainfallUnit, area, areaUnit, length, lengthUnit){
	//Run imperial to metric conversions
	
	rainfall = rainfallUnit == "m" ? rainfall : convert(rainfall).from('in').to('mm');
	area = areaUnit == "m" ? area : convert(area).from('ft2').to('m2');
	length = lengthUnit == "m" ? length : convert(length).from('ft').to('m');

	let flow = ((rainfall * area ) / 3600) / length;

	return roundTo(flow, 5);
}

//why does javascript not have this?
export function roundTo(val, places){
	return +(Math.round(val + "e+" + places) + "e-" + places);
}

export function formatDate(date){
	const monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."];

	return `${date.getUTCDate()} ${monthNames[date.getUTCMonth()]} ${date.getUTCFullYear()}`;
}
