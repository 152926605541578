var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "results" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Provide us with some basic information and get access to the recommended grates based on your input data."
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "call-to-section" }, [
      _c("h3", [_vm._v("Catchment hydraulics and results")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xs-12 col-sm-7 col-md-2" }, [
          _vm._v("Area of catchment")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
          _vm._v(_vm._s(_vm.area) + _vm._s(_vm.areaUnit === "m" ? "m²" : "ft²"))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-7 col-md-2" }, [
          _vm._v("Crossfall")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
          _vm._v(
            _vm._s(_vm.reducedCrossfall) +
              "% " +
              _vm._s(_vm.crossfallStatus ? "(" + _vm.crossfallStatus + ")" : "")
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-7 col-md-2" }, [
          _vm._v("Standard")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
          _vm._v(_vm._s(_vm.drainStandard === "AUS" ? "AS 3996" : "EN 1433"))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-7 col-md-2" }, [
          _vm._v("Length of drain")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
          _vm._v(
            _vm._s(_vm.length) + _vm._s(_vm.lengthUnit === "m" ? "m" : "ft")
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-7 col-md-2" }, [
          _vm._v("Pavement geometry")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
          _vm._v(_vm._s(_vm.pavement))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-7 col-md-2" }, [
          _vm._v("Load class")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
          _vm._v(_vm._s(_vm.loadClass))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-7 col-md-2" }, [
          _vm._v("Rainfall Intensity")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
          _vm._v(
            _vm._s(_vm.rainfall) +
              _vm._s(_vm.rainfallUnit === "m" ? "mm/hr" : " inch/hr")
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-7 col-md-2" }, [
          _vm._v("Flow rate")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
          _vm._v(
            _vm._s(_vm.flowConverted()) +
              " " +
              _vm._s(_vm.flowRateUnit === "m" ? "L/s/m" : "GPM/ft")
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-7 col-md-2" }, [
          _vm._v("Channel width")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 col-sm-5 col-md-2 result" }, [
          _vm._v(_vm._s(_vm.grateWidthToText(_vm.grateNominalWidth)))
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.registered
      ? _c(
          "div",
          { staticClass: "result-table" },
          [
            _vm.loading
              ? _c("div", { staticClass: "loading" }, [
                  _c("span", [_vm._v("loading results...")]),
                  _vm._v(" "),
                  _c("div")
                ])
              : [
                  _vm.featuredGrate
                    ? [
                        _c("h2", { staticClass: "step-heading" }, [
                          _vm._v("Selected grate results")
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "The grate (slot) recommended must be used in a channel that has adequate hydraulic capacity."
                          )
                        ]),
                        _vm._v(" "),
                        _vm.featuredGrate
                          ? _c(
                              "div",
                              {
                                staticClass: "highlight results-row",
                                style: {
                                  borderColor: _vm.getHighlightColor(
                                    _vm.featuredGrate.Utilisation
                                  )
                                }
                              },
                              [
                                _c("results-row", {
                                  attrs: {
                                    isAUS: _vm.isAUS(),
                                    grateData: _vm.featuredGrate,
                                    highlight: true,
                                    "first-result": true,
                                    crossfall: _vm.reducedCrossfall
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.featuredGrate.Utilisation > 100
                          ? _c(
                              "div",
                              { staticClass: "alternative-grates" },
                              [
                                _vm.results === null || _vm.results.length > 0
                                  ? [
                                      _c(
                                        "h2",
                                        { staticClass: "step-heading" },
                                        [_vm._v("Alternative grates")]
                                      ),
                                      _vm._v(" "),
                                      _c("p", [
                                        _vm._v(
                                          "The grates below meet your hydraulic criteria and are listed in decreasing order of hydraulic capacity."
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.results, function(
                                        result,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: result.ID,
                                            staticClass: "results-row"
                                          },
                                          [
                                            _c("results-row", {
                                              attrs: {
                                                isAUS: _vm.isAUS(),
                                                grateData: result,
                                                "first-result":
                                                  index < 1 &&
                                                  !_vm.featuredGrate,
                                                crossfall: _vm.reducedCrossfall
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      })
                                    ]
                                  : _c("div", { staticClass: "no-results" }, [
                                      _c("p", [
                                        _vm._v(
                                          "We could not find a grate that meets your hydraulic requirements, try a grate of a higher load class or a grate of a different width."
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn",
                                          attrs: { onclick: "backToAco()" }
                                        },
                                        [_vm._v("Back to ACO")]
                                      )
                                    ])
                              ],
                              2
                            )
                          : _vm._e()
                      ]
                    : [
                        _vm.results !== null && _vm.results.length > 0
                          ? [
                              _c("h2", { staticClass: "step-heading" }, [
                                _vm._v("Grate results")
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "The list of grates below meet your hydraulic requirements and are based on the load class and grate width selected. The grates are ranked in order of highest " +
                                    _vm._s(_vm.$t("message.utilisation")) +
                                    " to the least " +
                                    _vm._s(_vm.$t("message.utilised")) +
                                    " and must be used in a channel that has adequate hydraulic capacity."
                                )
                              ]),
                              _vm._v(" "),
                              !_vm.featuredGrate ||
                              _vm.featuredGrate.Utilisation > 100
                                ? _vm._l(_vm.results, function(result, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: result.ID,
                                        staticClass: "results-row"
                                      },
                                      [
                                        _c("results-row", {
                                          attrs: {
                                            isAUS: _vm.isAUS(),
                                            grateData: result,
                                            "first-result":
                                              index < 1 && !_vm.featuredGrate,
                                            crossfall: _vm.reducedCrossfall
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                : _vm._e()
                            ]
                          : _c("div", { staticClass: "no-results" }, [
                              _c("p", [
                                _vm._v(
                                  "We could not find a grate that meets your hydraulic requirements, try a grate of a higher load class or a grate of a different width."
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn",
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("setStep", 1)
                                    }
                                  }
                                },
                                [_vm._v("Modify Values")]
                              )
                            ])
                      ],
                  _vm._v(" "),
                  _c("div", { staticClass: "cta-row no-print" }, [
                    _c("div", { staticClass: "cta" }, [
                      _c("h4", [_vm._v("What about the channel?")]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "Choose a channel system that works for my catchment hydraulics."
                        )
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        attrs: { src: "/images/KlassikDrain2.2.png", alt: "" }
                      }),
                      _vm._v(" "),
                      _c("ul", { staticClass: "cta-list" }, [
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-red btn-cta",
                              attrs: {
                                target: "_blank",
                                href: _vm.urlToPrefillHydrolite()
                              }
                            },
                            [_vm._v("Launch program")]
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.enquiryComplete
                    ? _c("div", { staticClass: "thanks-row" }, [
                        _vm._v(
                          "\n\t\t\t\tThank you for your interest! If you wish to make further " +
                            _vm._s(_vm.$t("message.enquiries")) +
                            " "
                        ),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.reset()
                              }
                            }
                          },
                          [_vm._v("click here")]
                        ),
                        _vm._v(" or modify your values "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("setStep", 1)
                              }
                            }
                          },
                          [_vm._v("here")]
                        ),
                        _vm._v(".\n\t\t\t")
                      ])
                    : _c("div", { staticClass: "enquire-row" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-red btn-enquire",
                            on: {
                              click: function($event) {
                                _vm.showEnquire = true
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("message.enquire").toUpperCase())
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "Select products and " +
                              _vm._s(_vm.$t("message.enquire")) +
                              " for more info (including blockage factors, higher crossfall and channel hydraulic reporting)."
                          )
                        ])
                      ])
                ]
          ],
          2
        )
      : _c("div", [
          _c("p", [
            _vm._v(
              "Please complete the registration form to immediately reveal the grate intake calculation results."
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "registration-wrapper" }, [
            _c("div", { staticClass: "row center-md" }, [
              _c("div", { staticClass: "col-xs-12 col-md-4" }, [
                _c(
                  "div",
                  { staticClass: "box" },
                  [
                    _c("registration", {
                      on: {
                        success: function($event) {
                          _vm.registered = true
                          _vm.getResults()
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ]),
    _vm._v(" "),
    _vm.showEnquire
      ? _c(
          "div",
          {
            staticClass: "backdrop",
            on: {
              click: function($event) {
                _vm.showEnquire = false
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "modal",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c("div", { staticClass: "header" }, [
                  _c("h3", [
                    _vm._v("Grate " + _vm._s(_vm.$t("message.Enquiry")))
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "body" },
                  [
                    _c("enquire-form", {
                      attrs: {
                        grates: _vm.selectedResults(),
                        submissionID: _vm.submissionID
                      },
                      on: {
                        success: function($event) {
                          _vm.enquiryComplete = true
                        },
                        close: function($event) {
                          _vm.showEnquire = false
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "step-heading" }, [
      _c("span", [_vm._v("Step 4:")]),
      _vm._v(" Register & view results")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }