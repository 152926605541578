<template>
	<div class="row">
		<div class="col-sm-5 col-md-3 result-row-img">
			<label class="result-row-select custom-checkbox">
				<input type="checkbox" v-model="grateData.selected"/>
				<span></span>
				SELECT
			</label>
			<div class="img-wrap">
			<img width="100%"
				@error="$event.target.src='/images/missing-img.jpg'"
				:src="`https://gicadmin.acotechsupport.com/images/Renders/scaled/${grateData.RenderID}.jpg`"
				@click="showEnlargedImg = true"/>
			</div>
		</div>
		<div class="col-sm-7 col-md-5">
			<div class="box result-row-info">
				<h2 v-html="parsedName(grateData.Name)"></h2>
				<p>Type: <span>{{grateData.Type}}</span></p>
				<p>Part no: <span>{{grateData.PartNo}}</span></p>
				<p>Load Class: <span>{{grateData.LoadClass}}kN</span></p>
				<p>Intake Area: <span>{{grateData.IntakeArea}}mm² / {{mm2Toin2(grateData.IntakeArea)}}in²</span></p>
				<p>Channel Systems: <span>{{grateData.ChannelSystems.join(', ')}}</span></p>

				<p v-if="grateData.SpecInfoURL == 'Contact ACO'" class="subtle-test" style="text-align: left">Specification sheet unavailable</p>
				<p v-else><a target="_blank" :href="grateData.SpecInfoURL">Grate Spec Info Data Sheet</a></p>
				<p v-if="!isAUS"><a target="_blank" href="http://www.acoinc.us/visualizer/">ACO Grate Visualizer Tool</a></p>
			</div>
		</div>
		<div class="col-sm-offset-4 col-md-offset-0 col-md-2 utilisation-col">
			<template v-if="grateData.Utilisation >= 0">
				<utilisation :percentage="grateData.Utilisation"></utilisation>
				<p>{{ $t('message.Utilisation') }}</p>
				<p class="subtle">Capacity is defined as 100%</p>
			</template>
		</div>
		<div class="col-md-2">
			<div class="box testing-video">
				<a @click="showVideos = true" class="link">
					<img src="/images/camera.png" />
					<p>Testing Video</p>
				</a>
				<a v-if="firstResult" target="_blank" href="https://www.acodrain.com.au/news/external-drainage-solutions-for-floods-and-storms.htm">More Info</a>
			</div>
		</div>

		<div class="backdrop" @click="showEnlargedImg = false" v-if="showEnlargedImg">
			<div class="modal modal-render" @click.stop>
				<div class="header">
					<h3 v-html="parsedName(grateData.Name)"></h3>
				</div>
				<div class="body">
					<img @error="$event.target.src='/images/missing-img.jpg'" :src="`https://gicadmin.acotechsupport.com/images/Renders/scaled/${grateData.RenderID}.jpg`"/>
					<div class="pull-right">
						<button class="btn" @click="showEnlargedImg = false;">Close</button>
					</div>
				</div>
			</div>
		</div>

		<div class="backdrop" @click="showVideos = false" v-if="showVideos">
			<div class="modal modal-videos" @click.stop>
				<div class="header">
					<h3 v-html="parsedName(grateData.Name) + ` at ${crossfallRounded()}% crossfall`"></h3>
				</div>
				<div class="body">
					<div class="row">
						<div class="col-md-6">
							<h5>Cam 1</h5>
							<video width="100%" loop autoPlay ref="cam1" name="media">
								<source :src="`https://gicadmin.acotechsupport.com/h264/S${grateData.VideoID}_G${crossfallRounded()}_Cam1.mp4`" type="video/mp4" />
							</video>
						</div>
						<div class="col-md-6">
							<h5>Cam 2</h5>
							<video width="100%" loop autoPlay ref="cam2" name="media">
								<source :src="`https://gicadmin.acotechsupport.com/h264/S${grateData.VideoID}_G${crossfallRounded()}_Cam2.mp4`" type="video/mp4" />
							</video>
						</div>
					</div>


					<div class="pull-right">
						<button class="btn" @click="showVideos = false;">Close</button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Utilisation from "./utilisation.vue";
import convert from 'convert-units';
import * as Helper from '../helper.js';

export default {
	props: {
		grateData: Object,
		highlight: Boolean,
		firstResult: Boolean,
		crossfall: Number,
		isAUS: Boolean
	},
	data: function(){
		return {
			showEnlargedImg: false,
			showVideos: false
		}
	},
	methods: {
		parsedName: function(name){
			name = name.replace('Heelsafe', 'Heelsafe®');
			name = name.replace('Anti-Slip', '<i>Anti-Slip</i>');

			return name;
		},
		crossfallRounded: function(){
			switch(true) {
				case this.crossfall <= 0.5:
					return '0.5';
				case this.crossfall <= 1.0:
					return '1';
				default:
					return '3';
			}
		},
		mm2Toin2: function(val){
			return Helper.roundTo(convert(val).from('mm2').to('in2'), 1);
		}
	},
	components: {
		'utilisation': Utilisation
	}
}
</script>
