var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calculator" }, [
    _c("div", { staticClass: "heading" }, [
      _c("div", { staticClass: "step-counter" }, [
        _c("div", { staticClass: "steps" }, [
          _c(
            "div",
            {
              staticClass: "step",
              on: {
                click: function($event) {
                  return _vm.setStep(1)
                }
              }
            },
            [
              _c("label", [
                _vm._v("\n\t\t\t\t\t\tStep 1\n\t\t\t\t\t\t"),
                _c("div", {
                  class: [
                    "circle",
                    { active: _vm.step === 1, complete: _vm.stepCompletion[1] }
                  ]
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { class: ["line", { complete: _vm.stepCompletion[1] }] }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "step",
              on: {
                click: function($event) {
                  return _vm.setStep(2)
                }
              }
            },
            [
              _c("label", [
                _vm._v("\n\t\t\t\t\t\tStep 2\n\t\t\t\t\t\t"),
                _c("div", {
                  class: [
                    "circle",
                    { active: _vm.step === 2, complete: _vm.stepCompletion[2] }
                  ]
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { class: ["line", { complete: _vm.stepCompletion[2] }] }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "step",
              on: {
                click: function($event) {
                  return _vm.setStep(3)
                }
              }
            },
            [
              _c("label", [
                _vm._v("\n\t\t\t\t\t\tStep 3\n\t\t\t\t\t\t"),
                _c("div", {
                  class: [
                    "circle",
                    { active: _vm.step === 3, complete: _vm.stepCompletion[3] }
                  ]
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { class: ["line", { complete: _vm.stepCompletion[3] }] }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "step",
              on: {
                click: function($event) {
                  return _vm.setStep(4)
                }
              }
            },
            [
              _c("label", [
                _vm._v("\n\t\t\t\t\t\tStep 4\n\t\t\t\t\t\t"),
                _c("div", { class: ["circle", { active: _vm.step === 4 }] })
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "center subtle",
            staticStyle: { "padding-top": "5px" }
          },
          [_vm._v("You may click back to an earlier step if required")]
        )
      ])
    ]),
    _vm._v(" "),
    _vm.step !== 4
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12 col-md-8" }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _vm.step === 1
                  ? _c("catchment", {
                      attrs: {
                        pavement: _vm.pavement,
                        rainfall: _vm.rainfall,
                        rainfallUnit: _vm.rainfallUnit,
                        area: _vm.area,
                        areaUnit: _vm.areaUnit,
                        length: _vm.length,
                        lengthUnit: _vm.lengthUnit,
                        crossfall: _vm.crossfall
                      },
                      on: {
                        next: _vm.setStep,
                        setAllUnits: _vm.setAllUnits,
                        "update:pavement": function($event) {
                          _vm.pavement = $event
                        },
                        "update:rainfall": function($event) {
                          _vm.rainfall = $event
                        },
                        "update:rainfallUnit": function($event) {
                          _vm.rainfallUnit = $event
                        },
                        "update:rainfall-unit": function($event) {
                          _vm.rainfallUnit = $event
                        },
                        "update:area": function($event) {
                          _vm.area = $event
                        },
                        "update:areaUnit": function($event) {
                          _vm.areaUnit = $event
                        },
                        "update:area-unit": function($event) {
                          _vm.areaUnit = $event
                        },
                        "update:length": function($event) {
                          _vm.length = $event
                        },
                        "update:lengthUnit": function($event) {
                          _vm.lengthUnit = $event
                        },
                        "update:length-unit": function($event) {
                          _vm.lengthUnit = $event
                        },
                        "update:crossfall": function($event) {
                          _vm.crossfall = $event
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step === 2
                  ? _c("water-run-off", {
                      attrs: {
                        flowRate: _vm.flowRate(),
                        flowRateUnit: _vm.flowRateUnit,
                        reducedCrossfall: _vm.reducedCrossfall(),
                        crossfallStatus: _vm.crossfallStatus
                      },
                      on: {
                        next: _vm.setStep,
                        "update:flowRateUnit": function($event) {
                          _vm.flowRateUnit = $event
                        },
                        "update:flow-rate-unit": function($event) {
                          _vm.flowRateUnit = $event
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.step === 3
                  ? _c("relevant-system", {
                      attrs: {
                        drainStandard: _vm.drainStandard,
                        loadClass: _vm.loadClass,
                        grateNominalWidth: _vm.grateNominalWidth,
                        preselectedGrate: _vm.preselectedGrate
                      },
                      on: {
                        next: _vm.setStep,
                        "update:drainStandard": function($event) {
                          _vm.drainStandard = $event
                        },
                        "update:drain-standard": function($event) {
                          _vm.drainStandard = $event
                        },
                        "update:loadClass": function($event) {
                          _vm.loadClass = $event
                        },
                        "update:load-class": function($event) {
                          _vm.loadClass = $event
                        },
                        "update:grateNominalWidth": function($event) {
                          _vm.grateNominalWidth = $event
                        },
                        "update:grate-nominal-width": function($event) {
                          _vm.grateNominalWidth = $event
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-12 col-md-4" }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c("preselected-grate", {
                  on: { setPreselected: _vm.preselectGrate }
                }),
                _vm._v(" "),
                _c("contact-aco")
              ],
              1
            )
          ])
        ])
      : _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12 col-md-12" }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c("results", {
                  attrs: {
                    pavement: _vm.pavement,
                    rainfall: _vm.rainfall,
                    rainfallUnit: _vm.rainfallUnit,
                    area: _vm.area,
                    areaUnit: _vm.areaUnit,
                    length: _vm.length,
                    lengthUnit: _vm.lengthUnit,
                    reducedCrossfall: _vm.reducedCrossfall(),
                    flowRateUnit: _vm.flowRateUnit,
                    flowRate: _vm.flowRate(),
                    drainStandard: _vm.drainStandard,
                    loadClass: _vm.loadClass,
                    grateNominalWidth: _vm.grateNominalWidth,
                    crossfallStatus: _vm.crossfallStatus,
                    preselectedGrate: _vm.preselectedGrate
                  },
                  on: { setStep: _vm.setStep }
                })
              ],
              1
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }