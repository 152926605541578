<template>
	<div class="contact-aco">
		<div class="header">
			<h2>Contact ACO</h2>
		</div>

		<div class="content">
			<p style="margin: 0 0 5px;">Select a region to {{ $t('message.enquire') }}</p>
			<select v-model="region" :class="{ placeholder: region == ''}">
				<option disabled selected value="">Region</option>
				<option>Australia</option>
				<option>Canada</option>
				<option>Mexico</option>
				<option>New Zealand</option>
				<option>USA</option>
				<option disabled value="">----------------</option>

				<option>Anguilla</option>
				<option>Antigua Barbuda</option>
				<option>Aruba</option>
				<option>Australia</option>
				<option>Barbados</option>
				<option>Belize</option>
				<option>Bermuda</option>
				<option>Brunei Darussalam</option>
				<option>Cambodia</option>
				<option>Canada</option>
				<option>Costa Rica</option>
				<option>Cuba</option>
				<option>Curacao</option>
				<option>Dominican Republic</option>
				<option>Fiji</option>
				<option>Grenada</option>
				<option>Guam</option>
				<option>Guatemala</option>
				<option>Haiti</option>
				<option>Honduras</option>
				<option>Indonesia</option>
				<option>Jamaica</option>
				<option>Kiribati</option>
				<option>Malaysia</option>
				<option>Marshall Islands</option>
				<option>Mexico</option>
				<option>Micronesia</option>
				<option>Myanmar (Burma)</option>
				<option>Nauru</option>
				<option>New Zealand</option>
				<option>Nicaragua</option>
				<option>Palau</option>
				<option>Panama</option>
				<option>Papua New Guinea</option>
				<option>Philippines</option>
				<option>Puerto Rico</option>
				<option>Sint Maarten</option>
				<option>Samoa</option>
				<option>Singapore</option>
				<option>Solomon Islands</option>
				<option>St. Kitts - Nevis</option>
				<option>St. Lucia</option>
				<option>St. Vincent - Grenadines</option>
				<option>Thailand</option>
				<option>Tonga</option>
				<option>Tonga</option>
				<option>Trinidad and Tobago</option>
				<option>Turks & Caicos</option>
				<option>Tuvalu</option>
				<option>USA</option>
				<option>Vanuatu</option>
				<option>Vietnam</option>
				<option>Virgin Islands</option>
			</select>

			<div v-if="region">
				<div v-if="showUSA()" class="contact-info">
					<h3>USA</h3>
					<p><a href="mailto:info@acousa.com">info@acousa.com</a></p>
					<p><a class="no-link-style" href="tel:1 800-543-4764">1 800 543 4764</a></p>
				</div>
				<div v-else-if="showNewZealand()" class="contact-info">
					<h3>New Zealand</h3>
					<p><a href="mailto:info@aconz.co.nz">info@aconz.co.nz</a></p>
					<p><a class="no-link-style" href="tel:0800-448-080">0800 448 080</a></p>
				</div>
				<div v-else-if="showCanada()" class="contact-info">
					<h3>Canada</h3>
					<p><a href="mailto:info@acocan.ca">info@acocan.ca</a></p>
					<p><a class="no-link-style" href="tel:877-226-4255">877 226 4255</a></p>
				</div>
				<div v-else-if="showMexico()" class="contact-info">
					<h3>Mexico</h3>
					<p><a href="mailto:info@acomex.com.mx">info@acomex.com.mx</a></p>
					<p><a class="no-link-style" href="tel:(55)-5344-8547">(55) 5344 8547</a></p>
					<p><a class="no-link-style" href="tel:(55)-5343-4282">(55) 5343 4282</a></p>
					<p><a class="no-link-style" href="tel:(55)-5343-5648">(55) 5343 5648</a></p>
				</div>
				<div v-else-if="showAustralia()" class="contact-info">
					<h3>Australia</h3>
					<p><a href="mailto:sales@acoaus.com.au">sales@acoaus.com.au</a></p>
					<p><a class="no-link-style" href="tel:1300-765-226">1300 765 226</a></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
	},
	data: function() {
		return {
			region: ''
		}
	},
	methods: {
		showUSA: function(){
			const USA = ['Antigua Barbuda', 'Barbados', 'Belize', 'Costa Rica', 'Cuba', 'Dominican Republic', 'Grenada', 'Guam', 'Guatemala', 'Haiti', 'Honduras', 'Jamaica', 'Nicaragua', 'Panama', 'Puerto Rico', 'St. Kitts - Nevis', 'St. Lucia', 'St. Vincent - Grenadines', 'Trinidad and Tobago', 'Anguilla', 'Aruba', 'Bermuda', 'Curacao', 'Sint Maarten', 'Turks & Caicos', 'Virgin Islands', 'USA'];
			return USA.includes(this.region);
		},
		showNewZealand: function(){
			const NewZealand = ['New Zealand'];
			return NewZealand.includes(this.region);
		},
		showCanada: function(){
			const Canada = ['Canada'];
			return Canada.includes(this.region);
		},
		showMexico: function(){
			const Mexico = ['Mexico'];
			return Mexico.includes(this.region);
		},
		showAustralia: function(){
			const Australia = ['Brunei Darussalam', 'Cambodia', 'Tonga', 'Tuvalu', 'Vanuatu', 'Vietnam', 'Samoa', 'Singapore', 'Solomon Islands', 'Thailand', 'Papua New Guinea', 'Philippines', 'Micronesia', 'Myanmar (Burma)', 'Nauru', 'Palau', 'Kiribati', 'Malaysia', 'Marshall Islands', 'Indonesia', 'Fiji', 'Tonga', 'Australia'];
			return Australia.includes(this.region);
		}
	}
}
</script>
