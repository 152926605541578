var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.grate_id
    ? _c("div", [
        _vm.loading
          ? _c("div", { staticClass: "closed-grate loading" }, [
              _c("span", [_vm._v("loading grate info...")]),
              _vm._v(" "),
              _c("div")
            ])
          : _c("div", { staticClass: "closed-grate" }, [
              _c("h3", [_vm._v(_vm._s(_vm.description))]),
              _vm._v(" "),
              _c("div", [
                _c("img", {
                  attrs: {
                    height: "80",
                    src:
                      "https://gicadmin.acotechsupport.com/images/Renders/scaled/" +
                      _vm.renderID +
                      ".jpg"
                  },
                  on: {
                    error: function($event) {
                      $event.target.src = "/images/missing-img.jpg"
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "close-grate-info" }, [
                  _c("p", [
                    _c("b", [_vm._v("Country: ")]),
                    _vm._v(_vm._s(_vm.country))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("b", [_vm._v("Load class: ")]),
                    _vm._v(_vm._s(_vm.loadClass))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("b", [_vm._v("Nominal Width: ")]),
                    _vm._v(
                      _vm._s(
                        _vm.nominalWidth.Valid && _vm.nominalWidth.Int64 !== 0
                          ? _vm.nominalWidth.Int64 + "mm"
                          : "Slot"
                      )
                    )
                  ])
                ])
              ])
            ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }