<template>
	<div class="catchment">
		<h2 class="step-heading"><span>Step 1:</span> Catchment hydraulics</h2>
		<p>Provide us with the initial values so we can determine the flow rate and speed of the surface water run-off.</p>

		<div class="row">
			<div class="col-xs-12 col-md-5">
				<div class="box relative">Pavement Geometry
					<img class="info" src="/icons/info.svg" />
					<div class="info-text"><img src="/images/pavement.gif"/></div>
				</div>
			</div>
			<div class="col-xs-12 col-md-5">
				<select
					:value="pavement"
					@change="$emit('update:pavement', $event.target.value)"
					:class="{ placeholder: pavement == ''}"
					v-validate="'required'"
					name="pavement">
					<option disabled selected value="">Select</option>
					<option value="on-grade">On-grade (1 way slope)</option>
					<option value="sag">Sag (2 way slope)</option>
				</select>
				<div class="vee-err" v-show="errors.has('pavement')">{{ errors.first('pavement') }}</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 col-md-5">
				<div class="box relative">Rainfall Intensity
					<img class="info" src="/icons/info.svg" />
					<div class="info-text">Refer to your local rainfall map</div>
				</div>
			</div>
			<div class="col-xs-6 col-md-5">
				<input
					type="number"
					placeholder="Enter Value"
					:value="rainfall"
					@input="$emit('update:rainfall', $event.target.value == '' ? null : Number($event.target.value))"
					v-validate="'required'"
					name="rainfall"/>
				<div class="vee-err" v-show="errors.has('rainfall')">{{ errors.first('rainfall') }}</div>
			</div>
			<div class="col-xs-6 col-md-2">
				<select
					:value="rainfallUnit"
					@change="checkFirstUnit('rainfallUnit', $event.target.value)"
					:class="{ placeholder: rainfallUnit == ''}"
					v-validate="'required'"
					name="rainfallUnit">
					<option disabled selected value="">Select</option>
					<option value="m">mm / hr</option>
					<option value="i">inch / hr</option>
				</select>
				<div class="vee-err" v-show="errors.has('rainfallUnit')">Required</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 col-md-5">
				<div class="box">Drainage Area</div>
			</div>
			<div class="col-xs-6 col-md-5">
				<input
					type="number"
					placeholder="Enter Value"
					:value="area"
					@input="$emit('update:area', $event.target.value == '' ? null : Number($event.target.value))"
					v-validate="'required'"
					name="area"/>
				<div class="vee-err" v-show="errors.has('area')">{{ errors.first('area') }}</div>
			</div>
			<div class="col-xs-6 col-md-2">
				<select
					:value="areaUnit"
					@change="checkFirstUnit('areaUnit', $event.target.value)"
					:class="{ placeholder: areaUnit == ''}"
					v-validate="'required'"
					name="areaUnit">
					<option disabled selected value="">Select</option>
					<option value="m">m²</option>
					<option value="i">ft²</option>
				</select>
				<div class="vee-err" v-show="errors.has('areaUnit')">Required</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 col-md-5">
				<div class="box">Length of Drain</div>
			</div>
			<div class="col-xs-6 col-md-5">
				<input
					type="number"
					placeholder="Enter Value"
					:value="length"
					@input="$emit('update:length', $event.target.value == '' ? null : Number($event.target.value))"
					v-validate="'required'"
					name="length"/>
				<div class="vee-err" v-show="errors.has('length')">{{ errors.first('length') }}</div>
			</div>
			<div class="col-xs-6 col-md-2">
				<select
					:value="lengthUnit"
					@change="checkFirstUnit('lengthUnit', $event.target.value)"
					:class="{ placeholder: lengthUnit == ''}"
					v-validate="'required'"
					name="lengthUnit">
					<option disabled selected value="">Select</option>
					<option value="m">m</option>
					<option value="i">ft</option>
				</select>
				<div class="vee-err" v-show="errors.has('lengthUnit')">Required</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 col-md-5">
				<div class="box relative">Crossfall (%)
					<img class="info" src="/icons/info.svg" />
					<div class="info-text" style="margin-top: -50%">
						<img src="/images/pavement.gif"/>
						<div>For a quick result, calculations are limited to 0.5% to 3% crossfall. ACO can offer solutions up to 9% on enquiry.</div>
					</div>
				</div>
			</div>
			<div class="col-xs-12 col-md-5">
				<input
					type="number"
					placeholder="Enter Value"
					min="0"
					max="100"
					step="0.01"
					:value="crossfall"
					@input="$emit('update:crossfall', $event.target.value == '' ? null : Number($event.target.value))"
					v-validate="'required'"
					name="crossfall"/>
				<div class="vee-err" v-show="errors.has('crossfall')">{{ errors.first('crossfall') }}</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 col-md-offset-10 col-md-2">
				<div class="box">
					<button class="btn" style="width: 100%" @click="nextPage()">Next</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VeeValidate from 'vee-validate';

export default {
	props: {
		pavement: String,
		rainfall: Number,
		rainfallUnit: String,
		area: Number,
		areaUnit: String,
		length: Number,
		lengthUnit: String,
		crossfall: Number
	},
	methods: {
		nextPage: function() {
			this.$validator.validateAll().then((result) => {
				if (result) {
					this.$emit('next', 2);
				}
			});
		},

		//If this is the first time a unit has been select prefill all remaining
		checkFirstUnit: function(unit, value){
			this.$emit(`update:${unit}`, value)
			if(!this.rainfallUnit && !this.areaUnit && !this.lengthUnit){
				this.$emit('setAllUnits', value);
			}
		}
	}
}
</script>