var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { staticClass: "utilisation-svg", attrs: { viewBox: "-2 -2 92 92" } },
    [
      _c("circle", {
        attrs: {
          stroke: this.percentage < 100 ? "#ddd" : "red",
          cx: "44",
          cy: "44",
          r: "42",
          "stroke-width": "3",
          fill: "white"
        }
      }),
      _vm._v(" "),
      _c(
        "text",
        {
          attrs: {
            "font-weight": "lighter",
            fill: "#848484",
            "font-size": "20px",
            x: "44",
            y: "50",
            "text-anchor": "middle"
          }
        },
        [_vm._v(_vm._s(_vm.percentageToText(_vm.percentage)) + "%")]
      ),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: _vm.plotPath(44, 44, 43),
          stroke: _vm.getColor(_vm.percentage),
          "stroke-width": "4",
          fill: "transparent"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }