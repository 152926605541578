<template>
	<div class="water-run-off">
		<h2 class="step-heading"><span>Step 2:</span> Surface water run-off</h2>
		<p>Calculated uniform lateral flow.</p>

		<div class="row">
			<div class="col-xs-3 col-md-2">
				<div class="box">Flow rate</div>
			</div>
			<div class="col-xs-3 col-md-2">
				<div class="box">{{flowConverted()}}</div>
			</div>
			<div class="col-xs-6 col-md-4">
			<select
				:value="flowRateUnit"
				@change="$emit('update:flowRateUnit', $event.target.value)"
				:class="{ placeholder: flowRateUnit == ''}" >
				<option disabled selected value="">Select</option>
				<option value="m">L/s/m</option>
				<option value="i">GPM/ft</option>
			</select>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-3 col-md-2">
				<div class="box">Crossfall</div>
			</div>
			<div class="col-xs-3 col-md-2">
				<div class="box">{{reducedCrossfall}}%</div>
			</div>
			<div class="col-xs-6 col-md-4">
				<div class="box relative" v-if="crossfallStatus"><span class="highlight-red">{{`Crossfall ${crossfallStatus}.`}}</span>
					<img class="info" src="/icons/info.svg" />
					<div class="info-text">For a quick result, calculations are limited to 0.5% to 3% crossfall. ACO can offer solutions up to 9% on {{ $t('message.enquiry') }}.</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xs-12 col-md-offset-6 col-md-2">
				<div class="box">
					<button class="btn" style="width: 100%" @click="$emit('next', 3)">Next</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as Helper from '../helper.js';

export default {
	props: {
		flowRate: Number,
		flowRateUnit: String,
		reducedCrossfall: Number,
		crossfallStatus: String
	},
	methods:{
		flowConverted: function(){
			return this.flowRateUnit === 'm' ? this.flowRate : Helper.asGPMft(this.flowRate);
		}
	}
}
</script>