var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-5 col-md-3 result-row-img" }, [
      _c("label", { staticClass: "result-row-select custom-checkbox" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.grateData.selected,
              expression: "grateData.selected"
            }
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.grateData.selected)
              ? _vm._i(_vm.grateData.selected, null) > -1
              : _vm.grateData.selected
          },
          on: {
            change: function($event) {
              var $$a = _vm.grateData.selected,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(_vm.grateData, "selected", $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.grateData,
                      "selected",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.grateData, "selected", $$c)
              }
            }
          }
        }),
        _vm._v(" "),
        _c("span"),
        _vm._v("\n\t\t\tSELECT\n\t\t")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "img-wrap" }, [
        _c("img", {
          attrs: {
            width: "100%",
            src:
              "https://gicadmin.acotechsupport.com/images/Renders/scaled/" +
              _vm.grateData.RenderID +
              ".jpg"
          },
          on: {
            error: function($event) {
              $event.target.src = "/images/missing-img.jpg"
            },
            click: function($event) {
              _vm.showEnlargedImg = true
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-7 col-md-5" }, [
      _c("div", { staticClass: "box result-row-info" }, [
        _c("h2", {
          domProps: { innerHTML: _vm._s(_vm.parsedName(_vm.grateData.Name)) }
        }),
        _vm._v(" "),
        _c("p", [
          _vm._v("Type: "),
          _c("span", [_vm._v(_vm._s(_vm.grateData.Type))])
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Part no: "),
          _c("span", [_vm._v(_vm._s(_vm.grateData.PartNo))])
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Load Class: "),
          _c("span", [_vm._v(_vm._s(_vm.grateData.LoadClass) + "kN")])
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Intake Area: "),
          _c("span", [
            _vm._v(
              _vm._s(_vm.grateData.IntakeArea) +
                "mm² / " +
                _vm._s(_vm.mm2Toin2(_vm.grateData.IntakeArea)) +
                "in²"
            )
          ])
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Channel Systems: "),
          _c("span", [_vm._v(_vm._s(_vm.grateData.ChannelSystems.join(", ")))])
        ]),
        _vm._v(" "),
        _vm.grateData.SpecInfoURL == "Contact ACO"
          ? _c(
              "p",
              {
                staticClass: "subtle-test",
                staticStyle: { "text-align": "left" }
              },
              [_vm._v("Specification sheet unavailable")]
            )
          : _c("p", [
              _c(
                "a",
                {
                  attrs: { target: "_blank", href: _vm.grateData.SpecInfoURL }
                },
                [_vm._v("Grate Spec Info Data Sheet")]
              )
            ]),
        _vm._v(" "),
        !_vm.isAUS
          ? _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    target: "_blank",
                    href: "http://www.acoinc.us/visualizer/"
                  }
                },
                [_vm._v("ACO Grate Visualizer Tool")]
              )
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "col-sm-offset-4 col-md-offset-0 col-md-2 utilisation-col"
      },
      [
        _vm.grateData.Utilisation >= 0
          ? [
              _c("utilisation", {
                attrs: { percentage: _vm.grateData.Utilisation }
              }),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.$t("message.Utilisation")))]),
              _vm._v(" "),
              _c("p", { staticClass: "subtle" }, [
                _vm._v("Capacity is defined as 100%")
              ])
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-2" }, [
      _c("div", { staticClass: "box testing-video" }, [
        _c(
          "a",
          {
            staticClass: "link",
            on: {
              click: function($event) {
                _vm.showVideos = true
              }
            }
          },
          [
            _c("img", { attrs: { src: "/images/camera.png" } }),
            _vm._v(" "),
            _c("p", [_vm._v("Testing Video")])
          ]
        ),
        _vm._v(" "),
        _vm.firstResult
          ? _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href:
                    "https://www.acodrain.com.au/news/external-drainage-solutions-for-floods-and-storms.htm"
                }
              },
              [_vm._v("More Info")]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _vm.showEnlargedImg
      ? _c(
          "div",
          {
            staticClass: "backdrop",
            on: {
              click: function($event) {
                _vm.showEnlargedImg = false
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "modal modal-render",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c("div", { staticClass: "header" }, [
                  _c("h3", {
                    domProps: {
                      innerHTML: _vm._s(_vm.parsedName(_vm.grateData.Name))
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "body" }, [
                  _c("img", {
                    attrs: {
                      src:
                        "https://gicadmin.acotechsupport.com/images/Renders/scaled/" +
                        _vm.grateData.RenderID +
                        ".jpg"
                    },
                    on: {
                      error: function($event) {
                        $event.target.src = "/images/missing-img.jpg"
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "pull-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        on: {
                          click: function($event) {
                            _vm.showEnlargedImg = false
                          }
                        }
                      },
                      [_vm._v("Close")]
                    )
                  ])
                ])
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showVideos
      ? _c(
          "div",
          {
            staticClass: "backdrop",
            on: {
              click: function($event) {
                _vm.showVideos = false
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "modal modal-videos",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c("div", { staticClass: "header" }, [
                  _c("h3", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.parsedName(_vm.grateData.Name) +
                          " at " +
                          _vm.crossfallRounded() +
                          "% crossfall"
                      )
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("h5", [_vm._v("Cam 1")]),
                      _vm._v(" "),
                      _c(
                        "video",
                        {
                          ref: "cam1",
                          attrs: {
                            width: "100%",
                            loop: "",
                            autoPlay: "",
                            name: "media"
                          }
                        },
                        [
                          _c("source", {
                            attrs: {
                              src:
                                "https://gicadmin.acotechsupport.com/h264/S" +
                                _vm.grateData.VideoID +
                                "_G" +
                                _vm.crossfallRounded() +
                                "_Cam1.mp4",
                              type: "video/mp4"
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("h5", [_vm._v("Cam 2")]),
                      _vm._v(" "),
                      _c(
                        "video",
                        {
                          ref: "cam2",
                          attrs: {
                            width: "100%",
                            loop: "",
                            autoPlay: "",
                            name: "media"
                          }
                        },
                        [
                          _c("source", {
                            attrs: {
                              src:
                                "https://gicadmin.acotechsupport.com/h264/S" +
                                _vm.grateData.VideoID +
                                "_G" +
                                _vm.crossfallRounded() +
                                "_Cam2.mp4",
                              type: "video/mp4"
                            }
                          })
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pull-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        on: {
                          click: function($event) {
                            _vm.showVideos = false
                          }
                        }
                      },
                      [_vm._v("Close")]
                    )
                  ])
                ])
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }