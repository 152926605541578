<template>
	<div v-if="grate_id">
		<div v-if="loading" class="closed-grate loading">
			<span>loading grate info...</span>
			<div></div>
		</div>
		<div v-else class="closed-grate">
				<h3>{{description}}</h3>
			<div>
				<img height="80" @error="$event.target.src='/images/missing-img.jpg'" :src="`https://gicadmin.acotechsupport.com/images/Renders/scaled/${renderID}.jpg`"/>
				<div class="close-grate-info">
					<p><b>Country: </b>{{country}}</p>
					<p><b>Load class: </b>{{loadClass}}</p>
					<p><b>Nominal Width: </b>{{nominalWidth.Valid && nominalWidth.Int64 !== 0 ? `${nominalWidth.Int64}mm` : 'Slot'}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import * as Helper from '../helper.js';

export default {
	created: function() {
		this.grate_id = Helper.getURLParameter('grate_id');
		if(this.grate_id) {
			this.getClosedGrate(this.grate_id);
		}
	},
	data: function(){
		return {
			loading: false,
			grate_id: '',

			country: '',
			description: '',
			loadClass: '',
			nominalWidth: 0,
			renderID: ''
		}
	},
	methods: {
		getClosedGrate: function(id) {
			this.loading = true;
			axios.get(
				"/api/gratedata", 
				{
					params: {
						grate_id: id
					}
				}).then((res) => {
					this.loading = false;
					this.country = res.data.Country;
					this.description = res.data.Description;
					this.loadClass = res.data.LoadClass.Valid ? res.data.LoadClass.String : "null";
					this.nominalWidth = res.data.NominalWidth;
					this.renderID = res.data.RenderID;
					this.$emit('setPreselected', {
						grate_id: this.grate_id,
						drainStandard: this.country === 'AUS' ? 'AUS' : 'NA',
						loadClass: this.loadClass,
						grateNominalWidth: this.nominalWidth.Int64,
					})
					console.log(res);
				}).catch((err) => {
					this.loading = false;
					console.log(err);
				});
		}
	}
}
</script>