<template>
	<div class="calculator">
		<div class="heading">
			<div class="step-counter">
				<div class="steps">
					<div class="step" @click="setStep(1)">
						<label>
							Step 1
							<div :class="['circle', {active: step === 1, complete: stepCompletion[1]}]"></div>
						</label>
					</div>
					<div :class="['line', {complete: stepCompletion[1]}]"></div>
					<div class="step" @click="setStep(2)">
						<label>
							Step 2
							<div :class="['circle', {active: step === 2, complete: stepCompletion[2]}]"></div>
						</label>
					</div>
					<div :class="['line', {complete: stepCompletion[2]}]"></div>
					<div class="step" @click="setStep(3)">
						<label>
							Step 3
							<div :class="['circle', {active: step === 3, complete: stepCompletion[3]}]"></div>
						</label>
					</div>
					<div :class="['line', {complete: stepCompletion[3]}]"></div>
					<div class="step" @click="setStep(4)">
						<label>
							Step 4
							<div :class="['circle', {active: step === 4}]"></div>
						</label>
					</div>
				</div>
				<p class="center subtle" style="padding-top: 5px;">You may click back to an earlier step if required</p>
			</div>
		</div>

		<div class="row" v-if="step !== 4">
			<div class="col-xs-12 col-md-8">
				<div class="box">
					<catchment
						v-if="step === 1"
						@next="setStep"
						@setAllUnits="setAllUnits"
						:pavement.sync="pavement"
						:rainfall.sync="rainfall"
						:rainfallUnit.sync="rainfallUnit"
						:area.sync="area"
						:areaUnit.sync="areaUnit"
						:length.sync="length"
						:lengthUnit.sync="lengthUnit"
						:crossfall.sync="crossfall"
						/>
					<water-run-off
						v-if="step === 2"
						@next="setStep" 
						:flowRate="flowRate()"
						:flowRateUnit.sync="flowRateUnit"
						:reducedCrossfall="reducedCrossfall()"
						:crossfallStatus="crossfallStatus"/>
					<relevant-system
						v-if="step === 3"
						@next="setStep"
						:drainStandard.sync="drainStandard"
						:loadClass.sync="loadClass"
						:grateNominalWidth.sync="grateNominalWidth"
						:preselectedGrate="preselectedGrate" />
				</div>
			</div>
			<div class="col-xs-12 col-md-4">
				<div class="box">
					<preselected-grate @setPreselected="preselectGrate" />
					<contact-aco />
				</div>
			</div>
		</div>
		<div class="row" v-else>
			<div class="col-xs-12 col-md-12">
				<div class="box">
					<results 
						:pavement="pavement"
						:rainfall="rainfall"
						:rainfallUnit="rainfallUnit"
						:area="area"
						:areaUnit="areaUnit"
						:length="length"
						:lengthUnit="lengthUnit"
						:reducedCrossfall="reducedCrossfall()"
						:flowRateUnit="flowRateUnit"
						:flowRate="flowRate()"
						:drainStandard="drainStandard"
						:loadClass="loadClass"
						:grateNominalWidth="grateNominalWidth"
						:crossfallStatus="crossfallStatus"
						:preselectedGrate="preselectedGrate"
						@setStep="setStep"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Catchment from './catchment-hydraulics.vue'
import WaterRunOff from './water-run-off.vue'
import ContactACO from './contact-aco.vue';
import PreselectGrate from './preselected-grate.vue';
import RelevantSystem from './relevant-system.vue';
import Results from './results.vue';
import * as Helper from '../helper.js';

export default {
	props: {
		prefill: Object
	},
	mounted() {
		// Prefill some values based on those provided via the Vue instantiation in main.js
		this.rainfall = this.prefill.rainfall;
		this.rainfallUnit = this.areaUnit = this.lengthUnit = this.flowRateUnit = this.prefill.units; // (m)etric or (i)mperial
		this.area = this.prefill.area;
		this.length = this.prefill.length;
		this.crossfall = Helper.roundTo(this.prefill.crossfall, 2);
		this.grateNominalWidth = this.prefill.width;
	},
	data: function() {
		return {
			step: 1,
			stepCompletion: {
				1: false,
				2: false,
				3: false
			},
			preselectedGrate: null,
			
			//Field bindings:
			pavement: "",
			rainfall: null,
			rainfallUnit: "",
			area: null,
			areaUnit: "",
			length: null,
			lengthUnit: "",
			crossfall: null,
			crossfallStatus: "",

			flowRateUnit: "",

			drainStandard: "",
			loadClass: "",
			grateNominalWidth: null
		}
	},
	methods: {
		setStep: function(step) {
			switch(this.step) {
				case 1:
					this.stepCompletion[1] = (this.pavement && this.rainfall && this.rainfallUnit && this.area && this.areaUnit && this.length && this.lengthUnit && this.crossfall);
					break;
				case 2:
					this.stepCompletion[2] = Boolean(this.flowRateUnit);
					break;
				case 3:
					this.stepCompletion[3] = (this.drainStandard && this.loadClass && this.grateNominalWidth !== null);
					break;
			}
			this.step = step;
		},
		flowRate: function() {
			let flow = Helper.calculateFlow(
				this.rainfall,
				this.rainfallUnit,
				this.area,
				this.areaUnit,
				this.length,
				this.lengthUnit);

			return flow;
		},
		reducedCrossfall: function() {
			//clamp to 0.5 ~ 3.0
			let crossfall;
			if(this.crossfall < 0.5){
				this.crossfallStatus = "increased";
				crossfall = 0.5
			} else if(this.crossfall > 3.0) {
				this.crossfallStatus = "decreased";
				crossfall = 3.0;
			} else {
				this.crossfallStatus = "";
				crossfall = this.crossfall;
			}

			return Helper.roundTo(crossfall, 2);
		},
		setAllUnits: function(unit) {
			this.rainfallUnit = unit;
			this.areaUnit = unit;
			this.lengthUnit = unit;
			this.flowRateUnit = unit;
		},
		preselectGrate: function(data){
			this.preselectedGrate = data.grate_id;
			this.drainStandard = data.drainStandard;
			this.loadClass = data.loadClass;
			this.grateNominalWidth = data.grateNominalWidth;
		}
	},
	components: {
		'catchment': Catchment,
		'water-run-off': WaterRunOff,
		'contact-aco': ContactACO,
		'preselected-grate': PreselectGrate,
		'relevant-system': RelevantSystem,
		'results': Results
	}
}
</script>