<template>
	<svg class="utilisation-svg" viewBox="-2 -2 92 92">
		<circle :stroke="this.percentage < 100 ? '#ddd' : 'red'" cx="44" cy="44" r="42" stroke-width="3" fill="white"></circle>
		<text font-weight="lighter" fill="#848484" font-size="20px" x="44" y="50" text-anchor="middle">{{percentageToText(percentage)}}%</text>
		<path :d="plotPath(44, 44, 43)" :stroke="getColor(percentage)" stroke-width="4" fill="transparent"></path>
	</svg>
	
</template>

<script>
import * as Helper from '../helper.js';

export default {
	props: {
		percentage: Number
	},
	data: function(){
		return {
			showEnlargedImg: false
		}
	},
	methods: {
		plotPath: function(x, y, radius){
			const startAngle = 0;
			const endAngle = this.percentageToAngle(this.percentage);

			const [startX, startY] = this.polarToCartesian(x, y, radius, endAngle);
			const [endX, endY] = this.polarToCartesian(x, y, radius, startAngle);

			const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;

			const pathData = `M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${endX} ${endY}`;

			return pathData;
		},
		getColor: function(percentage){
			switch (true) {
				case percentage < 75:
					return "green";
				case percentage < 90:
					return "#ff850e";
				case percentage < 100:
					return "#ff3b3b"
				default:
					return "ff0000";
			}
		},
		polarToCartesian: function(centerX, centerY, radius, angleInDegrees) {
			const angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;

			const x = centerX + (radius * Math.cos(angleInRadians));
			const y = centerY + (radius * Math.sin(angleInRadians));
			return [x, y];
		},
		percentageToAngle: function(percentage){
			if(percentage > 100){
				percentage = 99.99;
			} else if(percentage < 0) {
				percentage = 0;
			}
			
			return 3.6 * percentage;
		},
		percentageToText: function(percentage){
			if(percentage < 0) {
				percentage = 0;
			}

			return Helper.roundTo(percentage, 0);
		}
	}
}
</script>