var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relevent-system" }, [
    _vm._m(0),
    _vm._v(" "),
    !_vm.preselectedGrate
      ? _c("p", [
          _vm._v(
            "Refining the selection criteria to identify correct load class and grate width."
          )
        ])
      : _c("p", [
          _vm._v(
            "The fields below have been pre-filled based on the preselected grate."
          )
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-md-6" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            class: { placeholder: _vm.drainStandard == "" },
            attrs: { name: "drainStandard", disabled: _vm.preselectedGrate },
            domProps: { value: _vm.drainStandard },
            on: { change: _vm.updateDrain }
          },
          [
            _c("option", { attrs: { disabled: "", selected: "", value: "" } }, [
              _vm._v("Select")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "AUS" } }, [
              _vm._v("AS 3996 (Australasia) ")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "NA" } }, [
              _vm._v("EN 1433 (North America)")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("drainStandard"),
                expression: "errors.has('drainStandard')"
              }
            ],
            staticClass: "vee-err"
          },
          [_vm._v(_vm._s(_vm.errors.first("drainStandard")))]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12 col-md-4" }, [
        _c("div", { staticClass: "box relative" }, [
          _vm._v("Load Class \n\t\t\t\t"),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.drainStandard == "AUS",
                expression: "drainStandard == 'AUS'"
              }
            ],
            staticClass: "info",
            attrs: { src: "/icons/info.svg" }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.drainStandard == "AUS",
                  expression: "drainStandard == 'AUS'"
                }
              ],
              staticClass: "info-text"
            },
            [
              _vm._v(
                "Weights shown for AS 3996 drain standard load classses are approximate individual wheel loads"
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-md-6" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            class: { placeholder: _vm.loadClass == "" },
            attrs: {
              disabled: _vm.drainStandard == "" || _vm.preselectedGrate,
              name: "loadClass"
            },
            domProps: { value: _vm.loadClass },
            on: {
              change: function($event) {
                return _vm.$emit("update:loadClass", $event.target.value)
              }
            }
          },
          [
            _c("option", { attrs: { disabled: "", selected: "", value: "" } }, [
              _vm._v("Select")
            ]),
            _vm._v(" "),
            _vm.drainStandard == "AUS"
              ? [
                  _c("option", { attrs: { value: "A 10" } }, [
                    _vm._v("A10kN (330kg)")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "B 80" } }, [
                    _vm._v("B80kN (2,670kg)")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "C 150" } }, [
                    _vm._v("C150kN (5,000kg)")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "D 210" } }, [
                    _vm._v("D210kN (8,000kg)")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "E 400" } }, [
                    _vm._v("E400kN (13,700kg)")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "F 600" } }, [
                    _vm._v("F600kN (20,000kg)")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "G 900" } }, [
                    _vm._v("G900kN (30,000kg)")
                  ])
                ]
              : [
                  _c("option", { attrs: { value: "A 15" } }, [
                    _vm._v("Class A - 3,372 lbs - 15kN (44-70psi)")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "B 125" } }, [
                    _vm._v("Class B - 28,100 lbs - 125kN (369-580psi)")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "C 250" } }, [
                    _vm._v("Class C - 56,200 lbs - 250kN (739-1160psi)")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "D 400" } }, [
                    _vm._v("Class D - 89,920 lbs - 400kN (1182-1856psi)")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "E 600" } }, [
                    _vm._v("Class E - 134,800 lbs - 600kN (1773-2785psi)")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "F 900" } }, [
                    _vm._v("Class F - 202,320 lbs - 900kN (2659-4177psi)")
                  ])
                ]
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("loadClass"),
                expression: "errors.has('loadClass')"
              }
            ],
            staticClass: "vee-err"
          },
          [_vm._v(_vm._s(_vm.errors.first("loadClass")))]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 col-md-6" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required",
                expression: "'required'"
              }
            ],
            class: { placeholder: _vm.grateNominalWidth == null },
            attrs: {
              name: "grateNominalWidth",
              disabled: _vm.preselectedGrate
            },
            domProps: { value: _vm.grateNominalWidth },
            on: {
              change: function($event) {
                _vm.$emit(
                  "update:grateNominalWidth",
                  $event.target.value == "" ? null : Number($event.target.value)
                )
              }
            }
          },
          [
            _c("option", { attrs: { disabled: "", selected: "", value: "" } }, [
              _vm._v("Select")
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "0" } }, [_vm._v("Slot")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "50" } }, [_vm._v('50mm (2")')]),
            _vm._v(" "),
            _c("option", { attrs: { value: "100" } }, [_vm._v('100mm (4")')]),
            _vm._v(" "),
            _c("option", { attrs: { value: "200" } }, [_vm._v('200mm (8")')]),
            _vm._v(" "),
            _c("option", { attrs: { value: "300" } }, [_vm._v('300mm (12")')])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("grateNominalWidth"),
                expression: "errors.has('grateNominalWidth')"
              }
            ],
            staticClass: "vee-err"
          },
          [_vm._v(_vm._s(_vm.errors.first("grateNominalWidth")))]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12 col-md-offset-8 col-md-2" }, [
        _c("div", { staticClass: "box" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              staticStyle: { width: "100%" },
              on: {
                click: function($event) {
                  return _vm.nextPage()
                }
              }
            },
            [_vm._v("Next")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "step-heading" }, [
      _c("span", [_vm._v("Step 3:")]),
      _vm._v(" Identifying relevant system")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 col-md-4" }, [
      _c("div", { staticClass: "box relative" }, [
        _vm._v("Drain Standard \n\t\t\t\t"),
        _c("img", { staticClass: "info", attrs: { src: "/icons/info.svg" } }),
        _vm._v(" "),
        _c("div", { staticClass: "info-text" }, [
          _vm._v(
            "National regulatory authority that governs grates, if in doubt contact ACO to assist with Load Class Selection"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 col-md-4" }, [
      _c("div", { staticClass: "box relative" }, [
        _vm._v("Grate Nominal Width \n\t\t\t\t"),
        _c("img", { staticClass: "info", attrs: { src: "/icons/info.svg" } }),
        _vm._v(" "),
        _c("div", { staticClass: "info-text" }, [
          _vm._v("The assumption is that the channel can handle the flow.")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }